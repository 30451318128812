import { MainGridWrapper } from "../../../styles/Main.styles";
import {
  Headline,
  CompanyDescription,
  DescriptionPicture,
  ExpertiseWrapper,
  DescriptionWrapper,
  ChallengesText,
  ChallengesPicture,
  GraphDescription,
  CompanyPicture1,
  CompanyPicture2,
  H3Tiempos,
  MobileExpertiseWrapper,
  ArrowWrapper,
} from "./Intro.styled";
import { Arrow } from "../../common/Arrow/Arrow.component";
import { H3 } from "../../../styles/Text.styles";
import Image from "next/image";
import { useState } from "react";

export const Intro = (props) => {
  const [image1Size, setImage1Size] = useState(
    { width: 0, height: 0 }
  );
  const [image2Size, setImage2Size] = useState(
    { width: 0, height: 0 }
  );
  const [image3Size, setImage3Size] = useState(
    { width: 0, height: 0 }
  );
  const [image4Size, setImage4Size] = useState(
    { width: 0, height: 0 }
  );

  return (
    <MainGridWrapper>
      <Headline
        dangerouslySetInnerHTML={{
          __html: props.headline,
        }}
      />
      <DescriptionWrapper>
        <CompanyDescription
          dangerouslySetInnerHTML={{
            __html: props.companyDescription,
          }}
        />
        <ExpertiseWrapper>
          <H3
            dangerouslySetInnerHTML={{
              __html: props.expertisewWapper,
            }}
          />
          <ArrowWrapper
            onClick={() =>
              window.scrollTo({ left: window.scrollX, top: document.getElementById("expertiseArrowFlag").offsetTop - 100, behavior: 'smooth' })
            }
          >
            <Arrow slim />
          </ArrowWrapper>
        </ExpertiseWrapper>
      </DescriptionWrapper>
      <DescriptionPicture loaded={image1Size.height !== 0}>
        <Image
          src={process.env.REACT_APP_MEDIA_SRC + props.descriptionPictureUrl}
          layout="responsive"
          alt={props.descriptionPictureAlt}
          height={image1Size.height}
          width={image1Size.width}
          onLoadingComplete={({ naturalHeight, naturalWidth }) =>
            setImage1Size({
              height: naturalHeight,
              width: naturalWidth
            })
          }
        />
      </DescriptionPicture>
      <MobileExpertiseWrapper>
        <H3
          dangerouslySetInnerHTML={{
            __html: props.mobileExpertiseWrapper,
          }}
        />
        <ArrowWrapper onClick={() =>
          window.scrollTo({ left: window.scrollX, top: document.getElementById("expertiseArrowFlag").offsetTop - 100, behavior: 'smooth' })
        }>
          <Arrow slim />
        </ArrowWrapper>
      </MobileExpertiseWrapper>
      <ChallengesText
        id="expertiseArrowFlag"
        dangerouslySetInnerHTML={{
          __html: props.challengeText,
        }}
      />
      <ChallengesPicture loaded={image2Size.height !== 0}>
        <Image
          src={process.env.REACT_APP_MEDIA_SRC + props.challengesPictureUrl}
          alt={props.challengesPictureAlt}
          layout="responsive"
          height={image2Size.height}
          width={image2Size.width}
          onLoadingComplete={({ naturalHeight, naturalWidth }) =>
            setImage2Size({
              height: naturalHeight,
              width: naturalWidth
            })
          }
        />
      </ChallengesPicture>
      <GraphDescription
        dangerouslySetInnerHTML={{
          __html: props.graphDescription,
        }}
      />
      <CompanyPicture1 loaded={image3Size.height !== 0}>
        <Image
          src={process.env.REACT_APP_MEDIA_SRC + props.companyPicture1Url}
          layout="responsive"
          alt={props.companyPicture1Url}
          height={image3Size.height}
          width={image3Size.width}
          onLoadingComplete={({ naturalHeight, naturalWidth }) =>
            setImage3Size({
              height: naturalHeight,
              width: naturalWidth
            })
          }
        />
      </CompanyPicture1>
      <H3Tiempos
        dangerouslySetInnerHTML={{
          __html: props.h3Tiempos,
        }}
      />
      <CompanyPicture2 loaded={image4Size.height !== 0}>
        <Image
          src={process.env.REACT_APP_MEDIA_SRC + props.companyPicture2Url}
          layout="responsive"
          alt={props.companyPicture2Alt}
          height={image4Size.height}
          width={image4Size.width}
          onLoadingComplete={({ naturalHeight, naturalWidth }) =>
            setImage4Size({
              height: naturalHeight,
              width: naturalWidth
            })
          }
        />
      </CompanyPicture2>
    </MainGridWrapper>
  );
};
