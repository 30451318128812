import styled from "styled-components";
import { ContinuousText, H2 } from "../../../styles/Text.styles";
import { deviceSizes } from "../../../styles/deviceSizes";

export const Subtitle = styled(ContinuousText)`
  margin-top: 75px;
  grid-column: 1/3;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column: 1/9;
    margin-top: 120px;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    grid-column: 1/4;
    margin-top: 155px;
  }
`;

export const Header = styled(H2)`
  margin-top: 10px;
  grid-column: 1/3;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column: 1/13;
  }
`;

export const PreviewWrapper = styled.div`
  margin: 39px 0px 33px 0px;
  grid-column: 1/3;
  @media only screen and (min-width: ${deviceSizes.tablet}){
    grid-column: 1/13;
  }
  @media only screen and (min-width: 861px) {
    grid-row-gap: 120px;
    margin: 62px 0px 53px 0px;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    margin: 75px 0px 65px 0px;
  }
  @media only screen and (min-width: ${deviceSizes.max_desktop}) {
  }
`;

export const ButtonWrapper = styled.div`
grid-column: 1/3;
margin-bottom: 85px;
width: max-content;
justify-self: center;
@media only screen and (min-width: ${deviceSizes.tablet}){
  grid-column: 1/13;
}
@media only screen and (min-width: ${deviceSizes.desktop}) {
  width: 305px;
}
@media only screen and (min-width: ${deviceSizes.max_desktop}) {
}
`
