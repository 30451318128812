import { MainGridWrapper } from "../../../styles/Main.styles";
import { Testimonials } from "./Testimonials/Testimonials.component";
import {
  Subtitle,
  Header,
  PreviewWrapper,
  ButtonWrapper,
} from "./CaseStudies.styled";
import { PreviewGrid } from "../../CaseStudies/Overview/PreviewGrid/PreviewGrid.component";
import { BasicButton } from "../../common/BasicButton/BasicButton.component";

export const CaseStudies = (props) => {
  return (
    <MainGridWrapper>
      <Subtitle>{props.subtitle}</Subtitle>
      <Header
        dangerouslySetInnerHTML={{
          __html: props.header,
        }}
      />
      <PreviewWrapper>
        <PreviewGrid
          cases={props.cases}
          noPadding
          customSlug={props.customSlug}
        />
      </PreviewWrapper>
      <ButtonWrapper>
        <BasicButton
          secondary
          label={"Weiter zu den Case Studies"}
          href={"/case-studies"}
          urlType={"Internal"}
        />
      </ButtonWrapper>
      <Testimonials testimonials={props.testimonials} />
    </MainGridWrapper>
  );
};
