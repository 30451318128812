import {
  Areas,
  Description,
  Title,
  Line,
  CloseButton,
  OpenButton,
  Wrapper,
} from "./KeyPoint.styles";
export const KeyPoint = ({ open, setOpen, index, title, description, areas }) => {
  return (
    <Wrapper onClick={() => setOpen(open ? null : index)}>
      <Title>{title}</Title>
      {open ? <CloseButton /> : <OpenButton />}
      <Description open={open}>{description}</Description>
      <Areas open={open}>{areas}</Areas>
      <Line></Line>
    </Wrapper>
  );
};
