import styled from "styled-components";
import { MainGridWrapper } from "../../../styles/Main.styles";
import { colors } from "../../../styles/colors";
import { deviceSizes } from "../../../styles/deviceSizes";
import { ContinuousText, H2 } from "../../../styles/Text.styles";

export const MainWrapper = styled(MainGridWrapper)`
  background: ${colors.uberUnsBeige};
  overflow: visible;
  padding-top: 40px;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    padding-top: 60px;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    padding-top: 140px;
  }
`;

export const Header = styled.div`
  grid-column: 1/3;
  display: grid;
  align-content: start;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column: 1/6;
    grid-template-columns: repeat(5, 1fr);
    align-content: start;
  }
  @media only screen and (min-width: ${deviceSizes.max_desktop}) {
    grid-template-columns: repeat(5, 90px);
  }
`;

export const WeAre = styled(ContinuousText)`
  grid-column: 1/2;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column: 1/6;
    align-self: start;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    grid-column: 1/6;
  }
`;

export const Headline = styled(H2)`
  margin: 10px 0px 30px 0px;
  grid-column: 1/3;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column: 1/6;
  }
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    margin: 10px 0px 50px 0px;
  }
`;

export const LearnButtonWrapper = styled.div`
  grid-column: 1/3;
  width: fit-content;
  margin-bottom: 50px;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    margin-bottom: 0px;
    grid-column: 1/5;
    grid-row-start: 3;
  }
`;

export const CompanyPicture1 = styled.div`
  justify-self: start;
  grid-column: 1/3;
  width: 100%;
  max-height: ${({ loaded }) => !loaded && 'calc((100%) * 9 / 16)'};;
  display: relative;
  background: ${({ loaded }) => !loaded && 'linear-gradient(90deg, #F2F2F2 1.35%, #f8f8f8 37.26%, #f8f8f8 66.49%, #F2F2F2 99.83%)'};
  background-size: 200% 100%;
	animation: ${({ loaded }) => !loaded && 'gradient 1.5s ease-in-out infinite'};
  @keyframes gradient {
    0% {
      background-position: 100% 0;
    }
    100% {
      background-position: -100% 0;
    }
  }
      & > img {
        width: 100%;
      }
    }
  }
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column: 1/7;
    width: 100%;
    margin: 50px 0px 0px 0px;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    margin: 100px 0px 0px 0px;
  }
`;

export const LeftSideWrapper = styled.div`
  grid-column: 1/3;
  display: grid;
  align-content: start;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column: 1/7;
  }
`;

export const RightSideWrapper = styled.div`
  grid-column: 1/3;
  display: grid;
  align-content: start;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column: 8/13;
  }
`;

export const CompanyPicture2 = styled.div`
  grid-column: 2/3;
  justify-self: end;
  width: calc(100% - 8px);
  display: relative;
  display: none;
  max-height: ${({ loaded }) => !loaded && 'calc((100% - 8px) * 16 / 9)'};
  background: ${({ loaded }) => !loaded && 'linear-gradient(90deg, #F2F2F2 1.35%, #f8f8f8 37.26%, #f8f8f8 66.49%, #F2F2F2 99.83%)'};
  background-size: 200% 100%;
	animation: ${({ loaded }) => !loaded && 'gradient 1.5s ease-in-out infinite'};
  @keyframes gradient {
    0% {
      background-position: 100% 0;
    }
    100% {
      background-position: -100% 0;
    }
  }
      & > img {
        width: 100%;
      }
    }
  }
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    display: inherit;
    grid-column: 8/13;
    width: 100%;
    grid-row-start: 1;
  }
`;

export const PositionBannerWrapper = styled.a`
  grid-column: 1/3;
  margin-top: 40px;
  background: ${colors.weis};
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  transform: translateY(20px);
  z-index: 2;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    padding: 30px;
    margin-top: 90px;
    grid-column: 8/13;
    transform: translateY(25px);
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    margin-top: 90px;
    padding: 62px 58px 62px 56px;
    gap: 63px;
    align-self: end;
    transform: translateY(35px);
  }
`;

export const PositionButtonWrapper = styled.div`
  width: fit-content;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column: 1/5;
    grid-row-start: 4;
  }
`;
