import styled from "styled-components";
import { MainGridWrapper } from "../../../styles/Main.styles";
import { colors } from "../../../styles/colors";
import { ContinuousText, H2 } from "../../../styles/Text.styles";
import { deviceSizes } from "../../../styles/deviceSizes";

export const MainWrapper = styled(MainGridWrapper)`
  background: ${colors.leistungenGrau};
`;

export const SolvingIssues = styled(ContinuousText)`
  margin-top: 40px;
  grid-column: 1/3;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    margin-top: 60px;
    grid-column: 1/8;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    margin-top: 140px;
    grid-column: 1/6;
  }
`;

export const Headline = styled(H2)`
  margin-top: 10px;
  grid-column: 1/3;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column: 1/11;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    grid-column: 1/12;
  }
`;

export const PointsWrapper = styled.div`
  grid-column: 1/3;
  margin-bottom: 10px;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column: 6/13;
    grid-row-start: 3;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    margin-top: 55px;
    margin-bottom: 110px;
  }
`;

export const ContactWrapper = styled.div`
  grid-column: 1/3;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column: 1/5;
    grid-row-start: 3;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
  }
`;

export const SecondaryText = styled(ContinuousText)`
  grid-column: 1/3;
  margin-top: 24px;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    margin-top: 40px;
    grid-column: 1/5;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    margin-top: 55px;
  }
`;

export const ButtonWrapper = styled.div`
  grid-column: 1/3;
  margin-top: 30px;
  margin-bottom: 50px;
  width: fit-content;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    margin-bottom: 0px;
    grid-column: 1/5;
    grid-row-start: 4;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    margin-top: 50px;
  }
`;
