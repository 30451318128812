import styled from "styled-components";
import { ContinuousText, H1, H2, H3 } from "../../../styles/Text.styles";
import { deviceSizes } from "../../../styles/deviceSizes";

export const Headline = styled(H1)`
  margin-top: 15px;
  grid-column: 1/3;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column: 1/13;
    text-indent: calc(((100% - 50px) / 12) * -1);
    padding-left: calc((100% - 50px) / 12);
    margin: 40px 0px 40px 0px;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    text-indent: -110px;
    padding-left: 110px;
    margin: 65px 0px 55px 0px;
  }
`;

export const CompanyDescription = styled(ContinuousText)`
  grid-column: 1/3;
  align-self: start;
  margin-bottom: 40px;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    margin-bottom: 0px;
    grid-column: 2/7;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
  }
`;

export const DescriptionPicture = styled.div`
  position: relative;
  grid-column: 1/3;
  width: 100%;
  min-height: ${({ loaded }) => !loaded && 'calc(100% * 3 / 4)'};
  background: ${({ loaded }) => !loaded && 'linear-gradient(90deg, #F2F2F2 1.35%, #f8f8f8 37.26%, #f8f8f8 66.49%, #F2F2F2 99.83%)'};
  background-size: 200% 100%;
	animation: ${({ loaded }) => !loaded && 'gradient 1.5s ease-in-out infinite'};
  @keyframes gradient {
    0% {
      background-position: 100% 0;
    }
    100% {
      background-position: -100% 0;
    }
  }
      & > img {
        width: 100%;
      }
    }
  }
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    align-self: center;
    
    grid-column: 8/13;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
  }
`;
export const ExpertiseWrapper = styled.div`
  flex-direction: column;
  gap: 4px;
  grid-column: 1/3;
  justify-self: center;
  justify-content: center;
  height: 100%;
  align-items: center;
  display: none;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    display: flex;
    grid-column: 2/7;
    gap: 30px;
    height: 100%;
    align-self: start;
    justify-self: start;
    justify-content: start;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    gap: 16px;
  }
`;
export const ArrowWrapper = styled.div`
  display: flex;
  justify-content: center;
  cursor: pointer;
  width: 44px;
  height: 45px;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    height: 90px;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    height: 388px;
  }
`;

export const MobileExpertiseWrapper = styled(ExpertiseWrapper)`
  display: flex;
  justify-content: start;
  margin-top: 40px;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    display: none;
  }
`;

export const DescriptionWrapper = styled.div`
  display: grid;
  grid-column: 1/3;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;
  margin-top: 15px;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column: 1/7;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: auto 1fr;
    grid-row-gap: 50px;
    margin-top: 0px;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    grid-column: 1/6;
    grid-template-columns: repeat(5, 1fr);
    grid-row-gap: 40px;
  }
  @media only screen and (min-width: ${deviceSizes.max_desktop}) {
    grid-template-columns: repeat(5, 90px);
  }
`;

export const ChallengesText = styled(H2)`
  grid-column: 1/3;
  margin-top: 75px;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column: 1/11;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    grid-column: 1/11;
  }
`;

export const ChallengesPicture = styled.div`
  margin-top: 40px;
  grid-column: 1/3;
  justify-self: center;
  display: relative;
  min-height: ${({ loaded }) => !loaded && 'calc(80% * 3 / 4)'};
  width: 80%;
  background: ${({ loaded }) => !loaded && 'linear-gradient(90deg, #F2F2F2 1.35%, #f8f8f8 37.26%, #f8f8f8 66.49%, #F2F2F2 99.83%)'};
  background-size: 200% 100%;
	animation: ${({ loaded }) => !loaded && 'gradient 1.5s ease-in-out infinite'};
  @keyframes gradient {
    0% {
      background-position: 100% 0;
    }
    100% {
      background-position: -100% 0;
    }
  }
      & > img {
        width: 100%;
      }
    }
  }
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column: 1/5;
    margin-top: 40px;
    width: calc(100% + 40px);
    justify-self: start;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    grid-column: 2/5;
  }
`;

export const GraphDescription = styled(ContinuousText)`
  margin-top: 40px;
  grid-column: 1/3;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column: 6/13;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    grid-column: 6/12;
  }
`;

export const CompanyPicture1 = styled.div`
  margin-top: 40px;
  grid-column: 1/2;
  justify-self: start;
  display: relative;
  width: calc(100% + 40px);
  max-height: ${({ loaded }) => !loaded && 'calc((100% + 40px) * 9 / 16)'};;
  background: ${({ loaded }) => !loaded && 'linear-gradient(90deg, #F2F2F2 1.35%, #f8f8f8 37.26%, #f8f8f8 66.49%, #F2F2F2 99.83%)'};
  background-size: 200% 100%;
	animation: ${({ loaded }) => !loaded && 'gradient 1.5s ease-in-out infinite'};
  @keyframes gradient {
    0% {
      background-position: 100% 0;
    }
    100% {
      background-position: -100% 0;
    }
  }
      & > img {
        width: 100%;
      }
    }
  }
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column: 1/8;
    width: 100%;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    margin-top: 90px;
  }
`;

export const CompanyPicture2 = styled.div`
  margin-top: 104px;
  grid-column: 2/3;
  display: relative;
  justify-self: end;
  grid-row-start: 8;
  width: 75%;
  max-height: ${({ loaded }) => !loaded && 'calc((75%) * 9 / 16)'};;
  background: ${({ loaded }) => !loaded && 'linear-gradient(90deg, #F2F2F2 1.35%, #f8f8f8 37.26%, #f8f8f8 66.49%, #F2F2F2 99.83%)'};
  background-size: 200% 100%;
	animation: ${({ loaded }) => !loaded && 'gradient 1.5s ease-in-out infinite'};
  @keyframes gradient {
    0% {
      background-position: 100% 0;
    }
    100% {
      background-position: -100% 0;
    }
  }
      & > img {
        width: 100%;
      }
    }
  }
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column: 9/13;
    grid-row: 5/7;
    align-self: center;
    margin: 0px;
    width: 100%;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
  }
`;

export const H3Tiempos = styled(H3)`
  margin: 40px 0px 75px 0px;
  grid-column: 1/3;
  font-family: "Tiempos Headline";
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column: 1/7;
    width: 100%;
    margin-bottom: 120px;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    margin-top: 90px;
    margin-bottom: 155px;
  }
`;
