import { Footer } from "../../common/Footer/Footer.component";
import { OurServices } from "../../Home/OurServices/OurServices.component";
import { CaseStudies } from "../../Home/CaseStudies/CaseStudies.component";
import { AboutUsTeaser } from "../../Home/AboutUsTeaser/AboutUsTeaser.component";
import { Intro } from "../../Home/Intro/Intro.component";
import { useState, useEffect } from "react";
import { removeParagraphs } from "../../../helpers";
import { StyledModal } from "../../common/StyledModal/StyledModal.component";


export const Home = ({
  caseStudies,
  caseStudiesSlug,
  servicesCF,
  navbarSwitch,
  setNavbarSwitch,
  footerCF,
  popup,
}) => {
  useEffect(() => {
    setModalOpen(popup.popupOptions.enabled);
  }, [popup]);
  const [modalOpen, setModalOpen] = useState(false);
  return (
    <>
      <StyledModal
        open={modalOpen}
        setOpen={setModalOpen}
        text={popup.popupContent}
        headline={popup.popupHeadline}
        icon={popup.popupIcon}
        button={popup.popupButton}
      />
      <Intro
        headline={servicesCF.introheadline}
        challengeText={servicesCF.challengestext}
        companyDescription={servicesCF.companydescription}
        expertisewWapper={servicesCF.expertisewrapper}
        graphDescription={servicesCF.graphdescription}
        h3Tiempos={servicesCF.h3tiempos}
        mobileExpertiseWrapper={servicesCF.expertisewWapper}
        descriptionPictureUrl={servicesCF.descriptionpicture.url}
        descriptionPictureAlt={servicesCF.descriptionpicture.alt}
        challengesPicture={servicesCF.challengespicture}
        challengesPictureUrl={servicesCF.challengespicture.url}
        challengesPictureAlt={servicesCF.challengespicture.alt}
        companyPicture1Url={servicesCF.companypicture1.url}
        companyPicture1Alt={servicesCF.companypicture1.alt}
        companyPicture2Url={servicesCF.companypicture2.url}
        companyPicture2Alt={servicesCF.companypicture2.alt}
      />
      <OurServices
        solvingIssues={servicesCF.solvingissues}
        headline={servicesCF.ourservicesheadline}
        secondaryText={servicesCF.secondarytext}
        basicButtonText={servicesCF.basicbutton.text}
        basicButtonUrl={servicesCF.basicbutton.url}
        basicButtonUrlType={servicesCF.basicbutton.linktype}
        basicButtonSubject={servicesCF.basicbutton.topic}
        keypoints={servicesCF.keypointflex}
      />
      <CaseStudies
        subtitle={servicesCF.subtitle}
        header={servicesCF.header}
        cases={caseStudies}
        customSlug={caseStudiesSlug}
        testimonials={servicesCF.testimonialsflex}
      />
      <AboutUsTeaser
        weAre={servicesCF.weare}
        headline={servicesCF.aboutheadline}
        basicButtonUrl={servicesCF.aboutbasicbutton.url}
        basicButtonText={servicesCF.aboutbasicbutton.text}
        basicButtonUrlType={servicesCF.aboutbasicbutton.linkType}
        basicButtonSubject={servicesCF.aboutbasicbutton.topic}
        companyPicture1Url={servicesCF.aboutcompanypicture1.url}
        companyPicture1Alt={servicesCF.aboutcompanypicture1.alt}
        companyPicture2Url={servicesCF.aboutcompanypicture2.url}
        companyPicture2Alt={servicesCF.aboutcompanypicture2.alt}
        positionBannerTitle={removeParagraphs(servicesCF.positionbanner.title)}
        positionBannerUrl={servicesCF.positionbanner.url}
        positionBannerLabel={servicesCF.positionbanner.label}
        positionBannerUrlType={servicesCF.positionbanner.linktype}
      />
      <Footer
        catchphrase={removeParagraphs(footerCF.catchphrase)}
        logo={footerCF.logo}
        navbarSwitch={navbarSwitch}
        setNavbarSwitch={setNavbarSwitch}
        basicButtonUrl={footerCF.basicbutton.url}
        basicButtonText={footerCF.basicbutton.text}
        basicButtonUrlType={footerCF.basicbutton.linktype}
        basicButtonSubject={footerCF.basicbutton.topic}
        contactsectionTitle={footerCF.contactsection.title}
        contactsectionAddress1={footerCF.contactsection.address1}
        contactsectionAddress2={footerCF.contactsection.address2}
        contactsectionPhoneUrl={footerCF.contactsection.phone.url}
        contactsectionPhoneText={footerCF.contactsection.phone.text}
        contactsectionEmailUrl={footerCF.contactsection.email.url}
        contactsectionEmailText={footerCF.contactsection.email.text}
        socialmediaText={footerCF.socialmedia.text}
        socialmediaUrl={footerCF.socialmedia.url}
        socialmediaTarget={footerCF.socialmedia.target}
        socialmediaReferral={footerCF.socialmedia.referral}
        menuTitle={footerCF.menu.title}
        menuLinks={footerCF.menu.linksflex}
        disclaimer={removeParagraphs(footerCF.disclaimer)}
        bottomLinkItem1Url={footerCF.bottomlink.item1.url}
        bottomLinkItem1Text={footerCF.bottomlink.item1.text}
        bottomLinkItem2Url={footerCF.bottomlink.item2.url}
        bottomLinkItem2Text={footerCF.bottomlink.item2.text}
        copyright1={footerCF.copyright.item1}
        copyright2={footerCF.copyright.item2}
      />
    </>
  );
};
