import styled from "styled-components";
import { ContinuousText, H3 } from "../../../../styles/Text.styles";
import CloseIcon from "../../../../public/closeIcon.svg";
import PlusIcon from "../../../../public/plusIcon.svg";
import { colors } from "../../../../styles/colors";
import { deviceSizes } from "../../../../styles/deviceSizes";

export const Wrapper = styled.div`
  grid-column: 1/3;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;
  cursor: pointer;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column: 6/13;
    grid-template-columns: repeat(7, 1fr);
    cursor: inherit;
  }
`;

export const Title = styled(H3)`
  grid-column: 1/2;
  white-space: nowrap;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column: 1/7;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    grid-column: 1/5;
  }
`;

export const Description = styled(ContinuousText)`
  margin: 10px 0px 20px 0px;
  display: ${({ open }) => (open ? "inherit" : "none")};
  grid-column: 1/3;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column: 1/8;
    margin: 15px 0px 15px 0px;
    display: inherit;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    grid-column: 1/8;
  }
`;

export const Areas = styled(ContinuousText)`
  font-weight: 500;
  grid-column: 1/3;
  display: ${({ open }) => (open ? "inherit" : "none")};
  padding-bottom: 10px;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column: 1/8;
    display: inherit;
    padding-bottom: 15px;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
  }
`;

export const Line = styled.div`
  grid-column: 1/3;
  border-bottom: 1px solid ${colors.anthrazit};
  margin-bottom: 30px;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column: 1/8;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
  }
`;

export const CloseButton = styled(CloseIcon)`
  margin-top: 24px;
  justify-self: end;
  align-self: center;
  color: ${colors.anthrazit};
  width: 15px;
  margin-top: 0px;
  grid-column: 2/3;
  cursor: pointer;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    display: none;
  }
`;

export const OpenButton = styled(PlusIcon)`
  margin-top: 24px;
  justify-self: end;
  align-self: center;
  color: ${colors.anthrazit};
  width: 14px;
  margin-top: 0px;
  grid-column: 2/3;
  cursor: pointer;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    display: none;
  }
`;
