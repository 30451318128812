import { BasicButton } from "../../common/BasicButton/BasicButton.component";
import {
  Headline,
  MainWrapper,
  WeAre,
  Header,
  LearnButtonWrapper,
  CompanyPicture1,
  CompanyPicture2,
  PositionBannerWrapper,
  PositionButtonWrapper,
  LeftSideWrapper,
  RightSideWrapper,
} from "./AboutUsTeaser.styled";
import { H3 } from "../../../styles/Text.styles";
import { useState } from "react";
import Image from "next/image";
import Link from "next/link";

export const AboutUsTeaser = (props) => {
  const [image1Size, setImage1Size] = useState(
    { width: 0, height: 0 }
  );
  const [image2Size, setImage2Size] = useState(
    { width: 0, height: 0 }
  );

  return (
    <MainWrapper>
      <LeftSideWrapper>
        <WeAre>{props.weAre}</WeAre>
        <Headline
          dangerouslySetInnerHTML={{
            __html: props.headline,
          }}
        />
        <LearnButtonWrapper>
          <BasicButton
            href={props.basicButtonUrl}
            label={props.basicButtonText}
            urlType={props.basicButtonUrlType}
            mailSubject={props.basicButtonSubject}
            secondary
          />
        </LearnButtonWrapper>
        <CompanyPicture1 loaded={image1Size.height !== 0}>
          <Image
            src={process.env.REACT_APP_MEDIA_SRC + props.companyPicture1Url}
            alt={props.companyPicture1Alt}
            layout="responsive"
            height={image1Size.height}
            width={image1Size.width}
            onLoadingComplete={({ naturalHeight, naturalWidth }) =>
              setImage1Size({
                height: naturalHeight,
                width: naturalWidth
              })
            }
          />
        </CompanyPicture1>
      </LeftSideWrapper>
      <RightSideWrapper>
        <CompanyPicture2 loaded={image2Size.height !== 0}>
          <Image
            src={process.env.REACT_APP_MEDIA_SRC + props.companyPicture2Url}
            alt={props.companyPicture2Alt}
            layout="responsive"
            height={image2Size.height}
            width={image2Size.width}
            onLoadingComplete={({ naturalHeight, naturalWidth }) =>
              setImage2Size({
                height: naturalHeight,
                width: naturalWidth
              })
            }
          />
        </CompanyPicture2>
        {props.positionBannerUrl ? (
          props.positionBannerUrlType === "Internal" ? (<Link href={props.positionBannerUrl} prefetch={false}>
            <PositionBannerWrapper>
              <H3
                dangerouslySetInnerHTML={{
                  __html: props.positionBannerTitle,
                }}
              />
              <PositionButtonWrapper>
                <BasicButton
                  href={props.positionBannerUrl}
                  label={props.positionBannerLabel}
                  urlType={props.positionBannerUrlType}
                  secondary
                />
              </PositionButtonWrapper>
            </PositionBannerWrapper>
          </Link>) : (
            <PositionBannerWrapper href={props.positionBannerUrlType === "Email" ? `mailto:${props.positionBannerUrl}` : props.positionBannerUrl} rel="noreferrer" target={props.positionBannerUrlType === "External" ? "_blank" : "_self"}>
              <H3
                dangerouslySetInnerHTML={{
                  __html: props.positionBannerTitle,
                }}
              />
              <PositionButtonWrapper>
                <BasicButton
                  secondary
                  label={props.positionBannerLabel}
                />
              </PositionButtonWrapper>
            </PositionBannerWrapper>
          )
        ) : (
          <PositionBannerWrapper>
            <H3
              dangerouslySetInnerHTML={{
                __html: props.positionBannerTitle,
              }}
            />
            <PositionButtonWrapper>
              <BasicButton
                secondary
                label={props.positionBannerLabel}
              />
            </PositionButtonWrapper>
          </PositionBannerWrapper>
        )}
      </RightSideWrapper>
    </MainWrapper>
  );
};
