import { BasicButton } from "../../common/BasicButton/BasicButton.component";
import {
  Headline,
  MainWrapper,
  SolvingIssues,
  SecondaryText,
  ButtonWrapper,
  PointsWrapper,
  ContactWrapper,
} from "./OurServices.styled";
import { KeyPoint } from "./KeyPoint/KeyPoint.component";
import { useState } from "react";

export const OurServices = (props) => {
  const [open, setOpen] = useState(null);
  return (
    <MainWrapper>
      <SolvingIssues
        dangerouslySetInnerHTML={{
          __html: props.solvingIssues,
        }}
      />
      <Headline
        dangerouslySetInnerHTML={{
          __html: props.headline,
        }}
      />
      <ContactWrapper>
        <SecondaryText
          dangerouslySetInnerHTML={{
            __html: props.secondaryText,
          }}
        />
        <ButtonWrapper>
          <BasicButton
            href={props.basicButtonUrl}
            label={props.basicButtonText}
            urlType={props.basicButtonUrlType}
            mailSubject={props.basicButtonSubject}
          />
        </ButtonWrapper>
      </ContactWrapper>
      <PointsWrapper>
      {props.keypoints.map((keypoint, index) => (
        <KeyPoint 
          key={index}
          index={index}
          open={open === index}
          setOpen={setOpen}
          title={keypoint.title}
          description={keypoint.description}
          areas={keypoint.areas}
        />
        ))}
      </PointsWrapper>
    </MainWrapper>
  );
};